import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      dark: {
        appBar: '#373b4f',
        background: '#263238',
        drawer: '#37474F',
        footer: '#373b4f',
        primary: '#37474F',
        secondary: '#373b4f',
        accent: '#779993'
      },
      light: {
        appBar: '#a8dbf4',
        background: '#e8ebf8',
        drawer: '#092A8C',
        footer: '#a8dbf4',
        primary: '#092A8C',
        secondary: '#a8dbf4',
        accent: '#b2192d'
      }
    }
  },
  lang: {
    locales: { en },
    current: 'en'
  }
})
